import React from 'react'
import { InputGroup } from 'react-bootstrap'

export default function MenuLink() {
  return (
    <InputGroup.Text id="" className="gap-1">
        <svg
        width="17"
        height="18"
        viewBox="0 0 17 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        >
        <path
            d="M2.68803 8.225H7.44954C7.62995 8.225 7.80296 8.14774 7.93053 8.01021C8.05809 7.87268 8.12976 7.68616 8.12976 7.49167V2.35833C8.12976 2.16384 8.05809 1.97732 7.93053 1.83979C7.80296 1.70226 7.62995 1.625 7.44954 1.625H2.68803C2.50762 1.625 2.33461 1.70226 2.20704 1.83979C2.07948 1.97732 2.00781 2.16384 2.00781 2.35833V7.49167C2.00781 7.68616 2.07948 7.87268 2.20704 8.01021C2.33461 8.14774 2.50762 8.225 2.68803 8.225ZM3.36824 3.09167H6.76933V6.75833H3.36824V3.09167Z"
            fill="black"
        />
        <path
            d="M10.1705 8.225H14.932C15.1124 8.225 15.2854 8.14774 15.4129 8.01021C15.5405 7.87268 15.6122 7.68616 15.6122 7.49167V2.35833C15.6122 2.16384 15.5405 1.97732 15.4129 1.83979C15.2854 1.70226 15.1124 1.625 14.932 1.625H10.1705C9.99005 1.625 9.81703 1.70226 9.68947 1.83979C9.5619 1.97732 9.49023 2.16384 9.49023 2.35833V7.49167C9.49023 7.68616 9.5619 7.87268 9.68947 8.01021C9.81703 8.14774 9.99005 8.225 10.1705 8.225ZM10.8507 3.09167H14.2517V6.75833H10.8507V3.09167Z"
            fill="black"
        />
        <path
            d="M2.68803 16.2953H7.44954C7.62995 16.2953 7.80296 16.2181 7.93053 16.0805C8.05809 15.943 8.12976 15.7565 8.12976 15.562V10.4286C8.12976 10.2342 8.05809 10.0476 7.93053 9.9101C7.80296 9.77257 7.62995 9.69531 7.44954 9.69531H2.68803C2.50762 9.69531 2.33461 9.77257 2.20704 9.9101C2.07948 10.0476 2.00781 10.2342 2.00781 10.4286V15.562C2.00781 15.7565 2.07948 15.943 2.20704 16.0805C2.33461 16.2181 2.50762 16.2953 2.68803 16.2953ZM3.36824 11.162H6.76933V14.8286H3.36824V11.162Z"
            fill="black"
        />
        <path
            d="M10.1705 16.2953H14.932C15.1124 16.2953 15.2854 16.2181 15.4129 16.0805C15.5405 15.943 15.6122 15.7565 15.6122 15.562V10.4286C15.6122 10.2342 15.5405 10.0476 15.4129 9.9101C15.2854 9.77257 15.1124 9.69531 14.932 9.69531H10.1705C9.99005 9.69531 9.81703 9.77257 9.68947 9.9101C9.5619 10.0476 9.49023 10.2342 9.49023 10.4286V15.562C9.49023 15.7565 9.5619 15.943 9.68947 16.0805C9.81703 16.2181 9.99005 16.2953 10.1705 16.2953ZM10.8507 11.162H14.2517V14.8286H10.8507V11.162Z"
            fill="black"
        />
        </svg>
        Menu
    </InputGroup.Text>
)
}
